import React, { useState } from "react";
import about from "../Assets/Img/about.jpg";
import crmdev from "../Assets/Img/crmdev.jpg";
import ecomdev from "../Assets/Img/ecommercedev.jpg";
import mobileappdev from "../Assets/Img/mobileapp.jpg";
import webdev from "../Assets/Img/webdev.jpg";
import facetimedev from "../Assets/Img/5064.jpg";
import seodev from "../Assets/Img/138498.jpg";
import graphicdesign from "../Assets/Img/graphicdesign.jpg";
import contact from "../Assets/Img/contact.jpg";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { db } from "../config/firebase";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Home() {
  const structuredData = {
    "@context": "http://schema.org",
    "@type": "LocalBusiness",
    name: "Tech-Rysen Web Development Services",
    url: "https://techrysen.com/tech-rysen-web-development-services",
    logo: "https://techrysen.com/images/tech-rysen-logo.png",
    description:
      "Professional web development services by Tech-Rysen. We offer bespoke website solutions tailored to your business needs.",
    address: {
      "@type": "PostalAddress",
      streetAddress: "",
      addressLocality: "Delhi",
      addressRegion: "Delhi",
      postalCode: "110009",
      addressCountry: "IN",
    },
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "+917086952212",
      contactType: "Customer Service",
    },
  };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [date, setDate] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [contact, setContact] = useState("");

  const handleForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (email || contact) {
      if (contact && contact.length < 10) {
        toast.error("Phone number can not be less than 10 digit");
        return;
      } else if (contact && contact.length > 13) {
        toast.error("Phone Number Can not ber greater than 13 digit");
        return;
      } else if (name.length < 1) {
        toast.error("Please Enter Name");
        return;
      } else {
        try {
          // console.log()
          const formObj = await db.collection("CustomerQueries").add({
            name: name,
            email: email,
            message: message,
            date: date,
            contact_no: contact,
          });
          Swal.fire({
            title: "Success",
            icon: "success",
            text: "Thank You for Connecting Us, We will get back to you soon ! ",
          });
          setName("");
          setContact("");
          setEmail("");
          setMessage("");
          setDate("");
          setLoading(false);
        } catch (err) {
          setLoading(false);
          toast.error("Something Went Wrong");
        }
      }
    } else {
      toast.error("Please Provide Email or Contact No .");
      setLoading(false);
      return;
    }
  };
  return (
    <>
      <Helmet>
        <title>TechRysen | Your Partner in Digital Growth</title>
        <meta
          name="description"
          content="Professional web development services by Tech-Rysen. We offer bespoke website solutions tailored to your business needs."
        />
        <meta
          name="keywords"
          content="Tech-Rysen, web development, web design, bespoke websites, business websites, web solutions"
        />
        <meta
          property="og:title"
          content="Tech-Rysen Web Development Services"
        />
        <meta
          property="og:description"
          content="Professional web development services by Tech-Rysen. We offer bespoke website solutions tailored to your business needs."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://techrysen.com/tech-rysen-web-development-services"
        />
        <meta
          property="og:image"
          content="https://techrysen.com/images/tech-rysen-web-development-services.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Tech-Rysen Web Development Services"
        />
        <meta
          name="twitter:description"
          content="Professional web development services by Tech-Rysen. We offer bespoke website solutions tailored to your business needs."
        />
        {/* <meta
          name="twitter:image"
          content="https://techrysen.com/"
        /> */}
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <ToastContainer />
      <div className="container-fluid position-relative p-0">
        <div className="container-fluid bg-primary py-5 mb-5 hero-header">
          <div className="container py-5">
            <div className="row justify-content-center py-5">
              <div className="col-lg-10 pt-lg-5 mt-lg-5 text-center">
                <h1
                  className="display-3 text-white mb-3 animated slideInDown"
                  style={{ fontFamily: "Koulen" }}
                >
                  Your Partner in Digital Growth
                </h1>
                <p className="fs-4 text-white mb-4 animated slideInDown">
                  "Empowering businesses with innovative tech solutions, driving
                  success in E-Commerce and CRM."
                </p>
                <div className="position-relative w-75 mx-auto animated slideInDown">
                  {/* <input
                    className="form-control border-0 rounded-pill w-100 py-3 ps-4 pe-5"
                    type="text"
                    placeholder="Eg: Thailand"
                  />
                  <button
                    type="button"
                    className="btn btn-primary rounded-pill py-2 px-4 position-absolute top-0 end-0 me-2"
                    style={{ marginTop: "7px" }}
                  >
                    Search
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- About Start --> */}
      <div class="container-xxl py-5">
        <div class="container">
          <div class="row g-5">
            <div
              class="col-lg-6 wow fadeInUp"
              data-wow-delay="0.1s"
              style={{ minHeight: "400px" }}
            >
              <div class="position-relative h-100">
                <img
                  class="img-fluid position-absolute w-100 h-100"
                  src={about}
                  alt="techRysen Your Partner in Digital Growth"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
            <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <h6 class="section-title bg-white text-start text-primary pe-3">
                About Us
              </h6>
              <h1 class="mb-4">
                Welcome to <span class="text-primary">TechRysen</span>
              </h1>
              <p class="mb-4">
                The TechRysen excels with unmatched expertise, innovative
                solutions, and a client-centric approach, delivering top-tier
                web and app development tailored to unique business needs.
              </p>
              <p class="mb-4">
                Established with a vision to revolutionize the digital
                landscape, we specialize in providing top-tier tech services,
                including CRM Development, Web Design & Development, E-Commerce
                Development, Mobile app development, and Graphic Design. Our
                mission is to empower businesses by transforming their digital
                presence and driving their success through cutting-edge
                technology and creative solutions.
              </p>

              <div class="row gy-2 gx-4 mb-4">
                <div class="col-sm-6">
                  <p class="mb-0">
                    <i class="fa fa-arrow-right text-primary me-2"></i>CRM
                    Development
                  </p>
                </div>
                <div class="col-sm-6">
                  <p class="mb-0">
                    <i class="fa fa-arrow-right text-primary me-2"></i>
                    Web Design & Development
                  </p>
                </div>
                <div class="col-sm-6">
                  <p class="mb-0">
                    <i class="fa fa-arrow-right text-primary me-2"></i>
                    E-Commerce Development
                  </p>
                </div>
                <div class="col-sm-6">
                  <p class="mb-0">
                    <i class="fa fa-arrow-right text-primary me-2"></i>Face Scan
                    Attendance System
                  </p>
                </div>
                <div class="col-sm-6">
                  <p class="mb-0">
                    <i class="fa fa-arrow-right text-primary me-2"></i>Mobile
                    App Development
                  </p>
                </div>
                <div class="col-sm-6">
                  <p class="mb-0">
                    <i class="fa fa-arrow-right text-primary me-2"></i>Search
                    Engine Optimization
                  </p>
                </div>

                {/* <div class="col-sm-6">
                  <p class="mb-0">
                    <i class="fa fa-arrow-right text-primary me-2"></i>24/7
                    Service
                  </p>
                </div> */}
              </div>
              <a class="btn btn-primary py-3 px-5 mt-2" href="">
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- About End --> */}

      {/* <!-- Service Start --> */}
      <div class="container-xxl py-5">
        <div class="container">
          <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 class="section-title bg-white text-center text-primary px-3">
              Services
            </h6>
            <h1 class="mb-5">Our Services</h1>
          </div>
          <div class="row g-4">
            <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
              <div class="service-item rounded">
                <img
                  style={{ width: "100%" }}
                  src={crmdev}
                  alt="crm development"
                />
                <div class="p-4">
                  {/* <i class="fa fa-3x fa-globe text-primary mb-4"></i> */}
                  <h5>CRM Development</h5>
                  <p>
                    Creating tailored CRM systems to streamline customer
                    interactions, improve data management, and enhance business
                    efficiency and growth.
                  </p>

                  <a href="/">
                    Learn More{" "}
                    <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
              <div class="service-item rounded">
                <img
                  style={{ width: "100%" }}
                  src={ecomdev}
                  alt="Ecommerce Development"
                />
                <div class="p-4">
                  <h5>E-Commerce Development</h5>
                  <p>
                    Developing user-friendly and secure eCommerce websites with
                    seamless navigation, integrated payment systems, and
                    optimized performance to boost online sales.
                  </p>
                  <a href="/">
                    Learn More{" "}
                    <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
              <div class="service-item rounded">
                <img
                  style={{ width: "100%" }}
                  src={webdev}
                  alt="Website Design and Development"
                />
                <div class="p-4">
                  <h5>Website Design & Development</h5>
                  <p>
                    We craft high-performance websites that are visually
                    stunning, user-friendly, and optimized for conversion.
                  </p>
                  <a href="/">
                    Learn More{" "}
                    <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
              <div class="service-item rounded">
                <img
                  style={{ width: "100%" }}
                  src={mobileappdev}
                  alt="Mobile App Development"
                />
                <div class="p-4">
                  <h5>Mobile Application Development</h5>
                  <p>
                    Creating high-performance mobile apps with intuitive design
                    and tailored functionality for your needs.
                  </p>
                  <a href="/">
                    Learn More{" "}
                    <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
              <div class="service-item rounded">
                <img
                  style={{ width: "100%" }}
                  src={facetimedev}
                  alt="Face Scan Attendance Software"
                />
                <div class="p-4">
                  <h5>Face Scan Attendance Software</h5>
                  <p>
                    Efficient attendance management using advanced facial
                    recognition for accurate, secure workforce tracking.
                  </p>
                  <a href="/">
                    Learn More{" "}
                    <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
              <div class="service-item rounded">
                <img
                  style={{ width: "100%" }}
                  src={seodev}
                  alt="Search Engine Optimization"
                />
                <div class="p-4">
                  <h5>Search Engine Optimization</h5>
                  <p>
                    We employ a strategic SEO approach to propel you to the top
                    of search engine results pages and drive organic traffic.
                  </p>
                  <a href="/">
                    Learn More{" "}
                    <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
              <div class="service-item rounded">
                <img
                  style={{ width: "100%" }}
                  src={graphicdesign}
                  alt="graphic design"
                />
                <div class="p-4">
                  <h5>Graphic Designing</h5>
                  <p>
                    Transforming ideas into visually compelling graphics, from
                    logos and branding to marketing materials, with creative and
                    professional design solutions.
                  </p>
                  <a href="/">
                    Learn More{" "}
                    <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Service End --> */}

      {/* <!-- Destination Start --> */}
      {/* <div class="container-xxl py-5 destination">
        <div class="container">
          <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 class="section-title bg-white text-center text-primary px-3">
              Destination
            </h6>
            <h1 class="mb-5">Popular Destination</h1>
          </div>
          <div class="row g-3">
            <div class="col-lg-7 col-md-6">
              <div class="row g-3">
                <div
                  class="col-lg-12 col-md-12 wow zoomIn"
                  data-wow-delay="0.1s"
                >
                  <a class="position-relative d-block overflow-hidden" href="">
                    <img class="img-fluid" src="img/destination-1.jpg" alt="" />
                    <div class="bg-white text-danger fw-bold position-absolute top-0 start-0 m-3 py-1 px-2">
                      30% OFF
                    </div>
                    <div class="bg-white text-primary fw-bold position-absolute bottom-0 end-0 m-3 py-1 px-2">
                      Thailand
                    </div>
                  </a>
                </div>
                <div
                  class="col-lg-6 col-md-12 wow zoomIn"
                  data-wow-delay="0.3s"
                >
                  <a class="position-relative d-block overflow-hidden" href="">
                    <img class="img-fluid" src="img/destination-2.jpg" alt="" />
                    <div class="bg-white text-danger fw-bold position-absolute top-0 start-0 m-3 py-1 px-2">
                      25% OFF
                    </div>
                    <div class="bg-white text-primary fw-bold position-absolute bottom-0 end-0 m-3 py-1 px-2">
                      Malaysia
                    </div>
                  </a>
                </div>
                <div
                  class="col-lg-6 col-md-12 wow zoomIn"
                  data-wow-delay="0.5s"
                >
                  <a class="position-relative d-block overflow-hidden" href="">
                    <img class="img-fluid" src="img/destination-3.jpg" alt="" />
                    <div class="bg-white text-danger fw-bold position-absolute top-0 start-0 m-3 py-1 px-2">
                      35% OFF
                    </div>
                    <div class="bg-white text-primary fw-bold position-absolute bottom-0 end-0 m-3 py-1 px-2">
                      Australia
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div
              class="col-lg-5 col-md-6 wow zoomIn"
              data-wow-delay="0.7s"
              style={{ minHeight: "350px" }}
            >
              <a
                class="position-relative d-block h-100 overflow-hidden"
                href=""
              >
                <img
                  class="img-fluid position-absolute w-100 h-100"
                  src="img/destination-4.jpg"
                  alt=""
                  style={{ objectFit: "cover" }}
                />
                <div class="bg-white text-danger fw-bold position-absolute top-0 start-0 m-3 py-1 px-2">
                  20% OFF
                </div>
                <div class="bg-white text-primary fw-bold position-absolute bottom-0 end-0 m-3 py-1 px-2">
                  Indonesia
                </div>
              </a>
            </div>
          </div>
        </div>
      </div> */}
      {/* <!-- Destination Start --> */}

      {/* <!-- Package Start --> */}
      {/* <div class="container-xxl py-5">
        <div class="container">
          <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 class="section-title bg-white text-center text-primary px-3">
              Packages
            </h6>
            <h1 class="mb-5">Awesome Packages</h1>
          </div>
          <div class="row g-4 justify-content-center">
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <div class="package-item">
                <div class="overflow-hidden">
                  <img class="img-fluid" src="{contact}" alt="" />
                </div>
                <div class="d-flex border-bottom">
                  <small class="flex-fill text-center border-end py-2">
                    <i class="fa fa-map-marker-alt text-primary me-2"></i>
                    Thailand
                  </small>
                  <small class="flex-fill text-center border-end py-2">
                    <i class="fa fa-calendar-alt text-primary me-2"></i>3 days
                  </small>
                  <small class="flex-fill text-center py-2">
                    <i class="fa fa-user text-primary me-2"></i>2 Person
                  </small>
                </div>
                <div class="text-center p-4">
                  <h3 class="mb-0">$149.00</h3>
                  <div class="mb-3">
                    <small class="fa fa-star text-primary"></small>
                    <small class="fa fa-star text-primary"></small>
                    <small class="fa fa-star text-primary"></small>
                    <small class="fa fa-star text-primary"></small>
                    <small class="fa fa-star text-primary"></small>
                  </div>
                  <p>
                    Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit
                    diam amet diam eos
                  </p>
                  <div class="d-flex justify-content-center mb-2">
                    <a
                      href="#"
                      class="btn btn-sm btn-primary px-3 border-end"
                      style={{ borderRadius: "30px 0 0 30px" }}
                    >
                      Read More
                    </a>
                    <a
                      href="#"
                      class="btn btn-sm btn-primary px-3"
                      style={{ borderRadius: "0px 30px 30px 0" }}
                    >
                      Book Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <div class="package-item">
                <div class="overflow-hidden">
                  <img class="img-fluid" src="img/package-2.jpg" alt="" />
                </div>
                <div class="d-flex border-bottom">
                  <small class="flex-fill text-center border-end py-2">
                    <i class="fa fa-map-marker-alt text-primary me-2"></i>
                    Indonesia
                  </small>
                  <small class="flex-fill text-center border-end py-2">
                    <i class="fa fa-calendar-alt text-primary me-2"></i>3 days
                  </small>
                  <small class="flex-fill text-center py-2">
                    <i class="fa fa-user text-primary me-2"></i>2 Person
                  </small>
                </div>
                <div class="text-center p-4">
                  <h3 class="mb-0">$139.00</h3>
                  <div class="mb-3">
                    <small class="fa fa-star text-primary"></small>
                    <small class="fa fa-star text-primary"></small>
                    <small class="fa fa-star text-primary"></small>
                    <small class="fa fa-star text-primary"></small>
                    <small class="fa fa-star text-primary"></small>
                  </div>
                  <p>
                    Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit
                    diam amet diam eos
                  </p>
                  <div class="d-flex justify-content-center mb-2">
                    <a
                      href="#"
                      class="btn btn-sm btn-primary px-3 border-end"
                      style={{ borderRadius: "30px 0 0 30px" }}
                    >
                      Read More
                    </a>
                    <a
                      href="#"
                      class="btn btn-sm btn-primary px-3"
                      style={{ borderRadius: "0px 30px 30px 0" }}
                    >
                      Book Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
              <div class="package-item">
                <div class="overflow-hidden">
                  <img class="img-fluid" src="img/package-3.jpg" alt="" />
                </div>
                <div class="d-flex border-bottom">
                  <small class="flex-fill text-center border-end py-2">
                    <i class="fa fa-map-marker-alt text-primary me-2"></i>
                    Malaysia
                  </small>
                  <small class="flex-fill text-center border-end py-2">
                    <i class="fa fa-calendar-alt text-primary me-2"></i>3 days
                  </small>
                  <small class="flex-fill text-center py-2">
                    <i class="fa fa-user text-primary me-2"></i>2 Person
                  </small>
                </div>
                <div class="text-center p-4">
                  <h3 class="mb-0">$189.00</h3>
                  <div class="mb-3">
                    <small class="fa fa-star text-primary"></small>
                    <small class="fa fa-star text-primary"></small>
                    <small class="fa fa-star text-primary"></small>
                    <small class="fa fa-star text-primary"></small>
                    <small class="fa fa-star text-primary"></small>
                  </div>
                  <p>
                    Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit
                    diam amet diam eos
                  </p>
                  <div class="d-flex justify-content-center mb-2">
                    <a
                      href="#"
                      class="btn btn-sm btn-primary px-3 border-end"
                      style={{ borderRadius: "30px 0 0 30px" }}
                    >
                      Read More
                    </a>
                    <a
                      href="#"
                      class="btn btn-sm btn-primary px-3"
                      style={{ borderRadius: "0px 30px 30px 0" }}
                    >
                      Book Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <!-- Package End --> */}

      {/* <!-- Booking Start --> */}
      <div class="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container">
          <div class="booking p-5">
            <div class="row g-5 align-items-center">
              <div class="col-md-6 text-white">
                <h6 class="text-white text-uppercase">Contact Us</h6>
                <h1 class="text-white mb-4">Online Consultant</h1>
                <p class="mb-4">
                  We specialize in eCommerce development and CRM systems,
                  offering expert solutions for your business needs.
                </p>
                <p class="mb-4">
                  For expert guidance and tailored solutions, contact us today.
                  Our online consultants are here to assist with all your
                  inquiries, provide detailed information, and offer
                  personalized advice. Reach out via phone, email, or our
                  website to explore how we can support your business needs and
                  drive success.
                </p>
                {/* <a class="btn btn-outline-light py-3 px-5 mt-2" href="">
                  Read More
                </a> */}
              </div>
              <div class="col-md-6">
                <h1 class="text-white mb-4">Book An Appoinment</h1>
                <form onSubmit={handleForm}>
                  <div class="row g-3">
                    <div class="col-md-6">
                      <div class="form-floating">
                        <input
                          type="text"
                          class="form-control bg-transparent"
                          id="name"
                          placeholder="Your Name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                        <label for="name">Your Name</label>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-floating">
                        <input
                          type="text"
                          class="form-control bg-transparent"
                          id="phone"
                          placeholder="Your Contact No."
                          value={contact}
                          onChange={(e) => setContact(e.target.value)}
                        />
                        <label for="phone">Phone</label>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-floating">
                        <input
                          type="email"
                          class="form-control bg-transparent"
                          id="email"
                          placeholder="Your Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <label for="email">Your Email</label>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div
                        class="form-floating date"
                        id="date3"
                        data-target-input="nearest"
                      >
                        <input
                          type="date"
                          class="form-control bg-transparent datetimepicker-input"
                          id="datetime"
                          placeholder="Preferred TIme Slot"
                          value={date}
                          onChange={(e) => setDate(e.target.value)}
                        />
                        <label for="datetime">Date & Time</label>
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="form-floating">
                        <textarea
                          class="form-control bg-transparent"
                          placeholder="Special Request"
                          id="message"
                          style={{ height: "100px" }}
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                        ></textarea>
                        <label for="message">Your Message</label>
                      </div>
                    </div>
                    <div class="col-12">
                      {loading ? (
                        <>
                          <button
                            class="btn btn-outline-light w-100 py-3"
                            disabled
                          >
                            Loading...
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            class="btn btn-outline-light w-100 py-3"
                            type="submit"
                          >
                            Book Now
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Booking Start --> */}

      {/* <!-- Process Start --> */}
      {/* <div class="container-xxl py-5">
        <div class="container">
          <div class="text-center pb-4 wow fadeInUp" data-wow-delay="0.1s">
            <h6 class="section-title bg-white text-center text-primary px-3">
              Process
            </h6>
            <h1 class="mb-5">3 Easy Steps</h1>
          </div>
          <div class="row gy-5 gx-4 justify-content-center">
            <div
              class="col-lg-4 col-sm-6 text-center pt-4 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div class="position-relative border border-primary pt-5 pb-4 px-4">
                <div
                  class="d-inline-flex align-items-center justify-content-center bg-primary rounded-circle position-absolute top-0 start-50 translate-middle shadow"
                  style={{ width: "100px", height: "100px" }}
                >
                  <i class="fa fa-globe fa-3x text-white"></i>
                </div>
                <h5 class="mt-4">Choose A Destination</h5>
                <hr class="w-25 mx-auto bg-primary mb-1" />
                <hr class="w-50 mx-auto bg-primary mt-0" />
                <p class="mb-0">
                  Tempor erat elitr rebum clita dolor diam ipsum sit diam amet
                  diam eos erat ipsum et lorem et sit sed stet lorem sit
                </p>
              </div>
            </div>
            <div
              class="col-lg-4 col-sm-6 text-center pt-4 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div class="position-relative border border-primary pt-5 pb-4 px-4">
                <div
                  class="d-inline-flex align-items-center justify-content-center bg-primary rounded-circle position-absolute top-0 start-50 translate-middle shadow"
                  style={{ width: "100px", height: "100px" }}
                >
                  <i class="fa fa-dollar-sign fa-3x text-white"></i>
                </div>
                <h5 class="mt-4">Pay Online</h5>
                <hr class="w-25 mx-auto bg-primary mb-1" />
                <hr class="w-50 mx-auto bg-primary mt-0" />
                <p class="mb-0">
                  Tempor erat elitr rebum clita dolor diam ipsum sit diam amet
                  diam eos erat ipsum et lorem et sit sed stet lorem sit
                </p>
              </div>
            </div>
            <div
              class="col-lg-4 col-sm-6 text-center pt-4 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div class="position-relative border border-primary pt-5 pb-4 px-4">
                <div
                  class="d-inline-flex align-items-center justify-content-center bg-primary rounded-circle position-absolute top-0 start-50 translate-middle shadow"
                  style={{ width: "100px", height: "100px" }}
                >
                  <i class="fa fa-plane fa-3x text-white"></i>
                </div>
                <h5 class="mt-4">Fly Today</h5>
                <hr class="w-25 mx-auto bg-primary mb-1" />
                <hr class="w-50 mx-auto bg-primary mt-0" />
                <p class="mb-0">
                  Tempor erat elitr rebum clita dolor diam ipsum sit diam amet
                  diam eos erat ipsum et lorem et sit sed stet lorem sit
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <!-- Process Start --> */}

      {/* <!-- Testimonial Start --> */}
      {/* <div class="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div class="container">
          <div class="text-center">
            <h6 class="section-title bg-white text-center text-primary px-3">
              Testimonial
            </h6>
            <h1 class="mb-5">Our Clients Say!!!</h1>
          </div>
          <div class="owl-carousel testimonial-carousel position-relative">
            <div class="testimonial-item bg-white text-center border p-4">
              <img
                class="bg-white rounded-circle shadow p-1 mx-auto mb-3"
                src="img/testimonial-1.jpg"
                style={{ width: "80px", height: "80px" }}
              />
              <h5 class="mb-0">John Doe</h5>
              <p>New York, USA</p>
              <p class="mb-0">
                Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam
                amet diam et eos. Clita erat ipsum et lorem et sit.
              </p>
            </div>
            <div class="testimonial-item bg-white text-center border p-4">
              <img
                class="bg-white rounded-circle shadow p-1 mx-auto mb-3"
                src="img/testimonial-2.jpg"
                style={{ width: "80px", height: "80px" }}
              />
              <h5 class="mb-0">John Doe</h5>
              <p>New York, USA</p>
              <p class="mt-2 mb-0">
                Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam
                amet diam et eos. Clita erat ipsum et lorem et sit.
              </p>
            </div>
            <div class="testimonial-item bg-white text-center border p-4">
              <img
                class="bg-white rounded-circle shadow p-1 mx-auto mb-3"
                src="img/testimonial-3.jpg"
                style={{ width: "80px", height: "80px" }}
              />
              <h5 class="mb-0">John Doe</h5>
              <p>New York, USA</p>
              <p class="mt-2 mb-0">
                Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam
                amet diam et eos. Clita erat ipsum et lorem et sit.
              </p>
            </div>
            <div class="testimonial-item bg-white text-center border p-4">
              <img
                class="bg-white rounded-circle shadow p-1 mx-auto mb-3"
                src="img/testimonial-4.jpg"
                style={{ width: "80px", height: "80px" }}
              />
              <h5 class="mb-0">John Doe</h5>
              <p>New York, USA</p>
              <p class="mt-2 mb-0">
                Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam
                amet diam et eos. Clita erat ipsum et lorem et sit.
              </p>
            </div>
          </div>
        </div>
      </div> */}
      {/* <!-- Testimonial End --> */}
    </>
  );
}

export default Home;
