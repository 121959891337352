import React from "react";

function Contact() {
  return (
    <>
      <div class="container-xxl py-5">
        <div class="container">
          <h6 class="section-title bg-white text-start text-primary pe-3">
            Contact Us
          </h6>
        </div>
      </div>
    </>
  );
}

export default Contact;
