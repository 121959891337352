import React from "react";
import logo from "../Assets/Img/logo.svg";
import { Link } from "react-router-dom";
function Navbar() {
  return (
    <>
      {/* <!-- Topbar Start --> */}
      <div className="container-fluid bg-dark px-5 d-none d-lg-block">
        <div className="row gx-0">
          <div className="col-lg-8 text-center text-lg-start mb-2 mb-lg-0">
            <div
              className="d-inline-flex align-items-center"
              style={{ height: "45px" }}
            >
              {/* <small className="me-3 text-light">
                <i className="fa fa-map-marker-alt me-2"></i>123 Street, New York,
                USA
              </small> */}
              <small className="me-3 text-light">
                <i className="fa fa-phone-alt me-2"></i>+917086952212;
                +917466093384
              </small>
              <small className="text-light">
                <i className="fa fa-envelope-open me-2"></i>techrysen@gmail.com
              </small>
            </div>
          </div>
          <div className="col-lg-4 text-center text-lg-end">
            <div
              className="d-inline-flex align-items-center"
              style={{ height: "45px" }}
            >
              <a
                style={{ fontSize: "12px" }}
                className="btn btn-sm btn-outline-light btn-sm-square me-2"
                href=""
              >
                <i className="fab fa-facebook-f fw-normal"></i>
              </a>
              <a
                style={{ fontSize: "12px" }}
                className="btn btn-sm btn-outline-light btn-sm-square me-2"
                href=""
              >
                <i className="fab fa-linkedin-in fw-normal"></i>
              </a>
              <a
                style={{ fontSize: "12px" }}
                className="btn btn-sm btn-outline-light btn-sm-square me-2"
                href=""
              >
                <i className="fab fa-instagram fw-normal"></i>
              </a>
              <a
                style={{ fontSize: "12px" }}
                className="btn btn-sm btn-outline-light btn-sm-square"
                href=""
              >
                <i className="fab fa-youtube fw-normal"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Topbar End --> */}

      <nav className="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
        <a href="" className="navbar-brand p-0">
          {/* <h1 className="text-primary m-0">
              <span style={{ color: "white" }}>Tech</span>Rysen
            </h1> */}
          <img src={logo} alt="Logo" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span className="fa fa-bars"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto py-0">
            <a href="" className="nav-item nav-link active">
              Home
            </a>
            <a href="" className="nav-item nav-link">
              About
            </a>

            {/* <div className="nav-item dropdown">
                <a
                  href="#"
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  Pages
                </a>
                <div className="dropdown-menu m-0">
                  <a href="destination.html" className="dropdown-item">
                    Destination
                  </a>
                  <a href="booking.html" className="dropdown-item">
                    Booking
                  </a>
                  <a href="team.html" className="dropdown-item">
                    Travel Guides
                  </a>
                  <a href="testimonial.html" className="dropdown-item">
                    Testimonial
                  </a>
                  <a href="404.html" className="dropdown-item">
                    404 Page
                  </a>
                </div>
              </div> */}
            <a href="" className="nav-item nav-link">
              Services
            </a>
            <Link to="/contact-us-tech-rysen" className="nav-item nav-link">
              Contact
            </Link>
          </div>
          {/* <a href="" className="btn btn-primary rounded-pill py-2 px-4">
              Register
            </a> */}
        </div>
      </nav>
    </>
  );
}

export default Navbar;
